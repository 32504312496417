import React, { useLayoutEffect } from 'react'
import { useController } from 'react-scroll-parallax'
import Sections from './Sections'

export function ParallaxContainer (props) {
  const ParallaxCache = () => {
    const { parallaxController } = useController()
    useLayoutEffect(() => {
      const handler = () => parallaxController.update()
      window.addEventListener('load', handler)
      return () => window.removeEventListener('load', handler)
    }, [parallaxController])
    return null
  }

  return (
    <>
      <ParallaxCache/>
      <Sections {...props} />
    </>
  )
}
