import React, { useEffect, useLayoutEffect } from 'react'
import { ParallaxContainer } from './parallax/ParallaxContainer'
import { ParallaxProvider } from 'react-scroll-parallax/cjs'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import { Impressum } from './Impressum'
import { Datenschutz } from './Datenschutz'
import MyNavbar from './MyNavbar'
import Footer from './Footer'
import { handleScroll } from './service/handleScroll'
import { LoadAllMarkdownFileContent } from './service/action/LoadAllMarkdownFileContent'

function App () {

  useLayoutEffect(() => {
    handleScroll()
  })

  useEffect(() => {
    LoadAllMarkdownFileContent();
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <div className="App">
      <MyNavbar/>
      <ParallaxProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/impressum" exact component={() => <Impressum/>}/>
            <Route path="/datenschutz" exact component={() => <Datenschutz/>}/>
            <Route path="/" component={() =>
              <ParallaxContainer currentPage={'home'}/>
            }/>
          </Switch>
        </BrowserRouter>
        <Footer/>
      </ParallaxProvider>
    </div>
  )
}

export default App
