import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import PropTypes from 'prop-types'

const style = {
  height: '100vh'
}
const bannerDefaultSettings = {
  amount: 0.5,
  expanded: false
}

export function ParallaxBackground (props) {
  const { image, children } = props
  const bannerSettings = {
    ...bannerDefaultSettings,
    image
  }
  return <ParallaxBanner style={style} layers={[bannerSettings]}>
    {children}
  </ParallaxBanner>
}

ParallaxBackground.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.object
}
