import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Footer.scss'
import { FaFax, FaPhone } from 'react-icons/fa'

const logo_white = '/static/img/logo_white.webp'
const currentYear = new Date().getFullYear();


export default function Footer () {
  return <section className={'page-footer section fp-auto-height'}>
    <Container>
      <Row>
        <Col sm={12}>
          <img
            alt="logo"
            src={logo_white}
            height="30px"
            className="align-top logo-white"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          &copy; 2020 - {currentYear} arcus(x) GmbH
        </Col>
        <Col sm={12} md={3}>
          Silcherstr. 15a <br/>22761 Hamburg
        </Col>
        <Col sm={12} md={3}>
          <a className={'footer-link'} href={'tel:+494033310292'}><FaPhone/> +49.40.33310292</a><br/>
          <FaFax/> +49.40.33310293
        </Col>
        <Col sm={12} md={3}>
          <a className={'footer-link'} href={'/impressum'}>Impressum</a><br/>
          <a className={'footer-link'} href={'/datenschutz'}>Datenschutz</a>
        </Col>
      </Row>
    </Container>
  </section>
}
