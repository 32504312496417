import { pages } from '../pages'

const pageUrls = [...pages.map(p => p.url)]

function getAnchorPoints () {
  const pagesTopPositions = {}
  const currScroll = window.scrollY
  const navbarHeight = document.getElementById('navbar').getBoundingClientRect().height
  pageUrls.forEach(pageUrl => {
    const elementById = document.getElementById(pageUrl)
    if (elementById) {
      let elementsTopPosition = elementById ? elementById.getBoundingClientRect().top : 0
      pagesTopPositions[pageUrl] = elementsTopPosition + currScroll - navbarHeight
    }
  })
  return pagesTopPositions
}

export function getAnimationStartSection () {
  const anchorPointsByPageUrl = getAnchorPoints()
  const shiftedAnchorPoints = anchorPointsByPageUrl;
  let sectionKeys = Object.keys(anchorPointsByPageUrl)
  const firstSection = anchorPointsByPageUrl[sectionKeys[0]]
  const secondSection = anchorPointsByPageUrl[sectionKeys[1]]
  const sectionShift = (secondSection - firstSection) / 4
  Object.entries(anchorPointsByPageUrl).forEach(
    ([key, section]) => shiftedAnchorPoints[key] = section - sectionShift
  )
  return calculateSectionByAnchorPoints(shiftedAnchorPoints)
}

function calculateSectionByAnchorPoints (anchorPointsByPageUrl) {
  const currPos = window.scrollY
  let currSection = null
  for (let i = 0; i < pageUrls.length; i++) {
    const currPageUrl = pageUrls[i]
    if (currPos >= anchorPointsByPageUrl[currPageUrl]) {
      currSection = currPageUrl
    }
  }
  const secondLastSection = pageUrls[pageUrls.length - 2]
  const lastSection = pageUrls[pageUrls.length - 1]
  const sectionDifference = anchorPointsByPageUrl[lastSection] - anchorPointsByPageUrl[secondLastSection]
  if (currSection === secondLastSection && anchorPointsByPageUrl[lastSection] - currPos < sectionDifference / 3) {
    currSection = lastSection
  }
  return currSection
}

export function getCurrentSection () {
  return calculateSectionByAnchorPoints(getAnchorPoints())
}
