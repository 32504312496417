import { EventEmitter } from 'events'
import { logger } from './logger'
import { handleError } from './handleError'

function upperFirst (s) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export class Dispatcher {
  constructor () {
    this.models = []
    this.eventEmitter = new EventEmitter()
    this.eventEmitter.setMaxListeners(200)
    this.viewDataState = {}
    this.actionQueue = []
    this.queueTimeout = null
    this._gatherData()
  }

  dispatchQueued (...actions) {
    if (this.queueTimeout) {
      clearTimeout(this.queueTimeout)
    }
    this.actionQueue.push(...actions)
    this.queueTimeout = setTimeout(() => {
      const executeActions = this.actionQueue
      this.actionQueue = []
      this.dispatch(...executeActions).catch(handleError)
    }, 20)
  }

  dispatch (...actions) {
    return new Promise((resolve, reject) => {
      try {
        for (let i = 0; i < actions.length; ++i) {
          const action = actions[i]
          this._dispatch(action)
        }
        const newStoreState = this._fireChanged()
        const actionsString = actions.map(action => action.type).join(', ')
        logger.debug(`Action ${actionsString} fired, new state: `, newStoreState)
        resolve()
      } catch (err) {
        reject(err)
      }
    })
  }

  _dispatch (action) {
    logger.debug(`Action ${action.type} firing: `, action)

    let handled = false
    const handlerName = 'on' + upperFirst(action.type)
    this.models.forEach((model) => {
      const handler = model[handlerName]
      if (handler) {
        handler.call(model, action)
        handled = true
      }
    })

    if (!handled) {
      // eslint-disable-next-line no-console
      console.warn('Unhandled action: %o', action)
    }
  }

  _gatherData () {

    this.models.forEach((model) => {
      if (model.appendDataTo) {
        model.appendDataTo(this.viewDataState)
      }
    })
    return this.viewDataState
  }

  _fireChanged () {
    const data = this._gatherData()
    // if (__DEV__) {
    //     // eslint-disable-next-line no-console
    //     console.debug('Updating UI %o', JSON.parse(JSON.stringify(data)));
    // }
    this.eventEmitter.emit('change', data)
    return data
  }

  subscribe (changeListener) {
    this.eventEmitter.addListener('change', changeListener)
    const data = this._gatherData()
    changeListener(data)
  }

  unsubscribe (l) {
    this.eventEmitter.removeListener('change', l)
  }

  registerModel (model) {
    this.models.push(model)
  }
}

export const dispatcher = new Dispatcher();
