import React from 'react'
import {ParallaxBackground} from './parallax/ParallaxBackground'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import {Animated} from 'react-animated-css'
import {useStoreValue} from './service/useStoreValue'
import './CommonPage.scss'

function getClassNameValue(pageName, classNames) {
    const myClassNames = ['page', pageName + '-page']
    myClassNames.push(classNames)
    return myClassNames.join(' ')
}

function CommonPage(props) {
    const {pageName, classNames, bgImage, url, animate = true, contentFromProps} = props
    const animationStartSection = useStoreValue((data) => data.uiSettings.animationStartSection, '')
    const currentSection = useStoreValue((data) => data.uiSettings.currentSection, '')
    const content = useStoreValue((data) => data.uiSettings.content[url], '')

    function getIsLandingPage() {
        return url === 'home'
    }

    function getMarkdownText() {
        const innerContainerStyle = getIsLandingPage() ? 'landing-inner-container' : 'inner-container'
        const contentToUse = contentFromProps || content;
        return <div className={'overflow-auto ' + innerContainerStyle}>
            <ReactMarkdown source={contentToUse} escapeHtml={false}/>
        </div>
    }

    function getAnimatedMarkdown(withMap) {
        const isVisible = animationStartSection === url
        return <Animated className='custom-animation' animationInDelay={500} animationInDuration={500} animationIn="fadeInUpBig" animationOut="fadeOut" isVisible={isVisible}>
            {getMarkdownText(withMap)}
        </Animated>
    }

    function getMarkdown(isAnimated, withMap) {
        return isAnimated ? getAnimatedMarkdown(withMap) : getMarkdownText(withMap)
    }

    function getContainer() {
        const isAnimated = animate;
        const withMap = pageName === 'kontakt' && currentSection==='kontakt'
        return <div className='outer-container'>
            {getMarkdown(isAnimated, withMap)}
        </div>
    }

    const classNameValue = getClassNameValue(pageName, classNames)
    return (
        <div className={classNameValue}>
            <ParallaxBackground image={bgImage}>
                {getContainer()}
            </ParallaxBackground>
        </div>
    )
}

CommonPage.propTypes = {
    pageName: PropTypes.string.isRequired,
    classNames: PropTypes.array,
    bgImage: PropTypes.any.isRequired,
    url: PropTypes.string,
    animate: PropTypes.bool,
    contentFromProps: PropTypes.string
}
export default CommonPage
