import CommonPage from './CommonPage'
import React from 'react'
import { useStoreValue } from './service/useStoreValue'
import {impressumPage} from './pages'

export function Impressum () {
  const content = useStoreValue((data)=>data.uiSettings.content[impressumPage.url])
  return <CommonPage classNames={['page']} pageName={impressumPage.pageName} animate={false} bgImage={impressumPage.bgImage}
                     contentFromProps={content}/>
}
