import { useCallback, useEffect, useState } from 'react'
import { dispatcher } from './Dispatcher'

export function useStoreValue (valueExtractor, initialValue) {
  const [value, setValue] = useState(initialValue)

  const modelChangedCallback = useCallback((data) => {
    setValue(valueExtractor(data))
  }, [valueExtractor])

  useEffect(() => {
    dispatcher.subscribe(modelChangedCallback)
    return () => {
      dispatcher.unsubscribe(modelChangedCallback)
    }
  }, [modelChangedCallback])

  return value
}
