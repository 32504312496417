import {allPages} from '../../pages'
import { dispatcher} from '../Dispatcher'

export function LoadAllMarkdownFileContent () {
  allPages.forEach(setMdContentFor);
}

function setMdContentFor (page) {
  fetch(page.mdFile).then((response) => {
    response.text().then(content=>{
      let pageContent = {name:page.url, content:content}
      return dispatcher.dispatch({
        type: 'setContent',
        data: {pageContent}
      })
    });
  })
}
