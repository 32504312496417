import React, { useCallback, useEffect } from 'react'
import './MyNavbar.scss'
import { Nav, Navbar } from 'react-bootstrap'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import { landingPage, pages } from './pages'
import { useStoreValue } from './service/useStoreValue'

const logo_white = '/static/img/logo_white.webp'
const logo_black = '/static/img/logo_black.webp'

const startPageUrl = landingPage.url
const unhandledPages = ['impressum', 'datenschutz']

function urlMatchesUnhandledUrls () {
  let isMatch = false
  unhandledPages.forEach(item => isMatch |= !!window.location.href.match(new RegExp(`.*${item}.*`)))
  return isMatch
}

function MyNavbar () {
  const currentSection = useStoreValue((data) => data.uiSettings.currentSection, '')
  const isScrolledToTop = useStoreValue((data) => data.uiSettings.isScrolledToTop, false)

  useEffect(() => {
    if (!urlMatchesUnhandledUrls()) {
      window.history.replaceState('', currentSection, '#' + currentSection)
    }
  }, [currentSection])

  const renderNavLinks = useCallback(() => {
    return pages.map(page => {
      const url = `/#${page.url}`
      const isActivePage = currentSection === page.url
      return <Nav.Item key={page.navHeading}>
        <Nav.Link className={isActivePage ? 'navigation-active' : 'navigation'}
                  href={url}>{page.navHeading}</Nav.Link>
      </Nav.Item>
    })
  }, [currentSection])

  const navbarTransparent = isScrolledToTop && !urlMatchesUnhandledUrls()

  return (
    <Navbar className={navbarTransparent ? 'navbar-transparent' : 'navbar-solid'} bg={'light'} variant="light"
            id='navbar' fixed="top" expand="lg" collapseOnSelect="true">
      <Navbar.Brand href={`/#${startPageUrl}`}>
        <img
          alt="logo"
          src={logo_white}
          height="30px"
          className="align-top logo-white"
        />
        <img
          alt="logo"
          src={logo_black}
          height="30px"
          className="align-top logo-black"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <div/>
        <Nav>
          {renderNavLinks()}
        </Nav>
        <Nav>
          <Nav.Item className='extra-info' key='phone'>
            <Nav.Link className={'navigation'} href='tel:+494033310292' title={'+49.40.33310292'}><FaPhone/></Nav.Link>
            <Nav.Link className={'navigation'} href='mailto:info@arcusx.com' title={'info@arcusx.com'}><FaEnvelope/></Nav.Link>
          </Nav.Item>
        </Nav>

      </Navbar.Collapse>
    </Navbar>
  )
}

export default MyNavbar
