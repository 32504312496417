import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.min.css'

import './service/registerStores'

ReactDOM.render(<App/>, document.getElementById('root'))
serviceWorker.unregister()
