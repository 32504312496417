import { getAnimationStartSection, getCurrentSection } from '../parallax/GetCurrentSection'
import { setCurrentSection } from './action/setCurrentPage'
import { setIsScrolledToTop } from './action/setIsScrolledToTop'
import { setAnimationStartSection } from './action/setAnimationStartSection'

export function handleScroll () {

  setCurrentSection(getCurrentSection())
  setAnimationStartSection(getAnimationStartSection())

  const threshold = window.innerHeight / 5
  let isAtTop = window.scrollY < threshold
  setIsScrolledToTop(isAtTop)
}
