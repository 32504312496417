function log (logFunc, args) {
  if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'stage') {
    return
  }

  if (!args || args.length === 0) {
    return
  }

  logFunc(...args)
}

export const logger = {
  log: (...args) => log(console.log, args),
  debug: (...args) => log(console.debug, args),
  warn: (...args) => log(console.warn, args),
  error: (...args) => log(console.error, args),
};
