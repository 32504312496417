import { dispatcher } from './Dispatcher'
import { appStore } from './store/AppStore'

const models = [appStore]
models.forEach((m) => dispatcher.registerModel(m))

const handlers = []

handlers.forEach((h) => dispatcher.registerModel(h))

dispatcher.dispatch()
