import { pages } from '../pages'
import CommonPage from '../CommonPage'
import React from 'react'

export default function Sections (props) {
  return pages.map(page => {
      return <section key={page.url} id={page.url}>
        <CommonPage {...page} {...props}   />
      </section>
    }
  )
}
