class AppStore {
  constructor () {
    this.uiSettings = {
      content: {},
      animationStartSection: '',
      currentSection: '',
      isScrolledToTop: false
    }
  }

  onSetContent ({ data }) {
    let content = JSON.parse(JSON.stringify(this.uiSettings.content));
    const {pageContent} = data
    content[pageContent.name] = pageContent.content
    this.uiSettings.content = content;
  }

  onSetCurrentSection ({ data }) {
    const { section } = data
    this.uiSettings.currentSection = section
  }

  onSetAnimationStartSection ({ data }) {
    const { section } = data
    this.uiSettings.animationStartSection = section
  }

  onSetIsScrolledToTop ({ data }) {
    const { isScrolledToTop } = data
    this.uiSettings.isScrolledToTop = isScrolledToTop
  }

  appendDataTo (data) {
    data.uiSettings = this.uiSettings
  }
}

export const appStore = new AppStore()
