const imageDir = `${process.env.PUBLIC_URL}/static/img`

const mdDir = `${process.env.PUBLIC_URL}/static/md`

function getImageUrl(imgName) {
  return `${imageDir}/${imgName}`

}
function getMdUrl(imgName) {
  return `${mdDir}/${imgName}`
}


export const landingPage = {
  mdFile: getMdUrl('LandingPage.md'),
  pageName: 'landing',
  animate: false,
  url: 'home',
  navHeading: 'Home',
  bgImage: getImageUrl('elphiTwilightPanorama.webp'),
}

const warumWirPage = {
  mdFile: getMdUrl('WarumWir.md'),
  pageName: 'warum-wir',
  url: 'warumwir',
  navHeading: 'Warum Wir ?',
  bgImage: getImageUrl('warumWir.svg'),
}

const beratungPage = {
  mdFile: getMdUrl('Beratung.md'),
  pageName: 'beratung',
  url: 'beratung',
  navHeading: 'Beratung',
  bgImage: getImageUrl('containerShipWithTowBoat.webp'),
}

const softwareModernisierungPage = {
  mdFile: getMdUrl('Modernisierung.md'),
  pageName: 'software-modernisierung',
  url: 'software',
  navHeading: 'Modernisierung',
  bgImage: getImageUrl('docksideCrane.webp'),
}

const entwicklungPage = {
  mdFile: getMdUrl('Entwicklung.md'),
  pageName: 'entwicklung',
  url: 'entwicklung',
  navHeading: 'Entwicklung',
  bgImage: getImageUrl('elphiUnderConstruction.webp'),
}

const kontaktPage = {
  mdFile: getMdUrl('Kontakt.md'),
  pageName: 'kontakt',
  url: 'kontakt',
  navHeading: 'Kontakt',
  bgImage: getImageUrl('bridgeWithShip.webp'),
}

export const impressumPage = {
  mdFile: getMdUrl('Impressum.md'),
  pageName: 'impressum',
  url: 'impressum',
  bgImage: getImageUrl('pileOfStones.webp'),
}
export const datenschutzPage = {
  mdFile: getMdUrl('Datenschutz.md'),
  pageName: 'datenschutz',
  url: 'datenschutz',
  bgImage: getImageUrl('pileOfStones.webp'),
}

export const pages = [landingPage, warumWirPage, softwareModernisierungPage, entwicklungPage, beratungPage, kontaktPage]
export const allPages = [landingPage, warumWirPage, softwareModernisierungPage, entwicklungPage, beratungPage, kontaktPage, impressumPage, datenschutzPage]
