import CommonPage from './CommonPage'
import React from 'react'
import { useStoreValue } from './service/useStoreValue'
import { datenschutzPage } from './pages';

export function Datenschutz () {
  const content = useStoreValue((data)=>data.uiSettings.content[datenschutzPage.url])
  return <CommonPage classNames={['page']} pageName={datenschutzPage.pageName} animate={false} bgImage={datenschutzPage.bgImage}
                     contentFromProps={content}/>
}
